body {
  background-color: #000;
  min-height: 100vh;
  text-align: center;
  color: #fff;
  font-family: 'Merriweather', serif;
}

section {
  padding-top: 60px;
  padding-bottom: 60px;
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 1.8em;
}

p {
  font-size: 1em;
  line-height: 1.6em;

}

nav {
  padding: 48px;
  display: flex;
}

a {
  color: #fff;
}

footer {
  display: flex;
  padding-right: 48px;
  padding-left: 48px;
  padding-top: 200px;
  padding-bottom: 100px;
}

.logo {
  width: 200px
}

.value-content {
  flex-grow: 2;
  padding: 40px;
  align-self: flex-end;
  max-width: 480px;
  min-width: 200px;
}

.value-image {
  height: 100%;
}

.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-image {
  width: 100%;
  height: 100%;
}

.hero-value-content {
  padding: 80px 24px;
  max-width: 700px;
}

.our-mission-section {
  display: flex;
  text-align: start;
  justify-content: space-between;
}

.our-mission-content {
  padding-left: 10%;
  padding-right: 60px;
}

.people-section {
  display: flex;
  flex-direction: row-reverse;
  text-align: start;
  justify-content: space-between;
}

.people-section-image {
  max-width: 800px;
}

.people-value-content {
  padding-right: 20%;
  padding-left: 60px;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 2.4em;
  }

  nav {
    flex-direction: column;
    align-items: center;
  }

  section {
    padding: 20px 0px;
  }

  .value-content {
    width: 80vw;
    padding-bottom: 20px;
    align-self: center;
  }

  .value-image {
    width: 100%;
    height: 100%;
  }

  .value-content {
    width: auto;
    padding: 20px;
  }

  .hero-value-content {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .our-mission-section {
    flex-direction: column;
  }


  .people-section {
    display: flex;
    flex-direction: column;
    text-align: start;
  }
}